import React, { useContext, useState } from 'react';
import Layout from '../components/layout';
import axios from '../helpers/axios-config'
import { navigate } from "gatsby";
import Seo from '../components/seo';
import {
    Container,
    Section,
    Flex,
    Box,
    Text,
    SubheadSmall,
    Button,
    Link,
    Subhead,
    Card
} from '../components/ui';
import {
    login_background,
    form_card,
    inputTextBox,
    submit_btn,
    loading_mask,
    loading
} from '../components/ui.css';
import login_background_img from '../images/login_background_img.png'
import LoadingMask from '../images/LoadingMask.gif'
import Usercontext from '../components/contextProvider/usercontext';
import { setUser } from '../components/Auth';
import { StaticImage } from 'gatsby-plugin-image';

const Login_page = () => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loadingImg, setLoading] = useState(false)
    const context = useContext(Usercontext)
    const [msg, setMsg] = useState("")
    const [errormessage, seterrorMessage] = useState({ username: "", password: "", isValidationFailed: true })

    const handledata = (e) => {
        if (e.target.name === "username") {
            setUsername(e.target.value)
        }
        else {
            setPassword(e.target.value)
        }
    }
    const ValidationRegistartionForm = () => {
        let errormessage = { name: "", isValidationFailed: false }
        if (username == "") {
            errormessage = {
                ...errormessage,
                username: "Please Enter The Email/Phone",
                isValidationFailed: true
            }
        }
        if (password == "") {
            errormessage = {
                ...errormessage,
                password: "Please Enter the Password",
                isValidationFailed: true
            }
        }
        return errormessage;
    }
    const submitForm = (e) => {
        const form = e.currentTarget;
        setLoading(true)
        e.preventDefault()
        let validationform = ValidationRegistartionForm();
        if (validationform.isValidationFailed) {
            setLoading(false)
            seterrorMessage(validationform)
        }
        else {
            const formData = {
                username,
                password
            }
            axios.post('/users/authenticate', formData)
                .then(response => {
                    let user = response.data;
                    setUser(user)
                     
                    
                    let currentUrl = window.location.href;
                   
                    if (currentUrl !== undefined && (currentUrl.includes('staging') || currentUrl.includes('localhost'))) {
                        navigate(`https://staging.portal.bizzlab.co.in/login_token?t=${user.token}`)
                    } else {
                        navigate(`https://portal.bizzlab.co.in/login_token?t=${user.token}`)
                    }
                     
                })
                .catch((error) => {
                    setLoading(false)
                    if (error) {
                        if (error.response) {
                            setMsg(error.response.data.message)
                        }
                    }
                });
        }
    }

    const reset = () => {
        setUsername("")
        setPassword("")
    }
    const validateEmail = (email) => {
        const regEx = RegExp(/\S+@\S+\.\S+/);
        return regEx.test(String(email).toLowerCase())
    }
    const renderErrorMessage = (field) => {
        return (
            errormessage.isValidationFailed && errormessage[field] !== " " &&
            <Text>
                <span style={{ color: "white" }}>{errormessage[field]}</span>
            </Text>
        )
    }
    return (
        <Layout navName="Login">
            <Seo title="Login" />
            {loadingImg &&
                <div className={loading}>
                    <span className={loading_mask} style={{ backgroundImage: `url(${LoadingMask})` }}>
                    </span>
                </div>}
            <Section className={login_background}>
                <Section >
                    <Container style={{
                        backgroundImage: `url(${login_background_img})`, backgroundRepeat: "no-repeat",
                        backgroundSize: "cover", height: "70vh", width: "100%"
                    }} >

                        <Flex gap={1} variant="responsive">
                            <Box>
                                <Card className={form_card}>

                                    <Flex gap={1} variant="responsive">
                                        <Box></Box>
                                        <Box><Subhead>Welcome!</Subhead></Box>
                                        <Box></Box>
                                    </Flex>

                                    <form onSubmit={submitForm}>
                                        <Text></Text>
                                        <Text>
                                            <label>Email/Phone</label>
                                            <input type="text" name="username" className={`${inputTextBox} $ "feild"`}
                                                onChange={handledata}
                                                value={username}
                                                invalid={errormessage.username === "" ? false : true} />
                                            {renderErrorMessage("username")}
                                        </Text>
                                        <Text>
                                            <label>Password</label>
                                            <input type="password" name="password" className={inputTextBox}
                                                onChange={handledata}
                                                value={password}
                                                invalid={errormessage.password === "" ? false : true} />
                                            {renderErrorMessage("password")}
                                        </Text>
                                        <Text>
                                            <lable>
                                                <input type="checkbox" name="remember Me" />{' '} Remember Me
                                            </lable>
                                        </Text>
                                        {msg !== '' && <Text style={{ color: 'red' }}> {msg} </Text>}
                                        <Text>
                                            <input type="submit" className={submit_btn} value="Sign in" />
                                        </Text>
                                        <Text></Text>
                                        <Link to="/forgot"><label style={{ color: "white", cursor: "pointer" }}> {' '}Forgot Password ? Click Here </label></Link>
                                        <Text></Text>
                                        <Flex gap={1} variant="responsive">
                                            <Box><hr /></Box>
                                            <Box><Text >New User?</Text></Box>
                                            <Box><hr /></Box>
                                        </Flex>
                                        <Button type="button" className={submit_btn}
                                        >Create Login</Button>
                                    </form>

                                </Card>
                            </Box>
                            <Box></Box>
                        </Flex>
                    </Container>

                </Section>
            </Section >
        </Layout>
    )
}
export default Login_page;